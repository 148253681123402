import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription, Observable } from 'rxjs';
import { DataSharingService } from '../../services/data-sharing.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.css']
})
export class ClockComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  time: Date;
  localTime: Date;
  timezoneOffset: number = new Date().getTimezoneOffset() / 60 || 0;
  gmtValue: string = 'GMT';

  isLocalTimezoneSelected = false;
  isTime24HrsSelected = false;

  constructor(public dataSharingService: DataSharingService,
    private toastrService: ToastrService) {
    this.isLocalTimezoneSelected = this.dataSharingService.isTimezoneLocal;
    this.isTime24HrsSelected = this.dataSharingService.isTime24Hrs;
  }

  ngOnInit() {
    this.getTime();
    this.getLocalTime();

    this.timezoneOffset = new Date().getTimezoneOffset() / 60;

    const offsetMinutes: number = new Date().getTimezoneOffset();
    const offsetHours: number = Math.abs(offsetMinutes / 60);
    const sign: string = offsetMinutes < 0 ? '+' : '-';

    // Format the offset string as "GMT+X" or "GMT-X"
    this.gmtValue = `GMT${sign}${offsetHours}`;

    const source = interval(1000);

    this.subscription = source.subscribe(val => {
      this.getTime();
      this.getLocalTime();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getTime(): void {
    const currentDate = new Date();
    this.time = new Date(currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds()
    );
  }

  getLocalTime(): void {
    const currentDate = new Date();
    this.localTime = new Date(currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );
  }


  onToggleTimezone() {
    this.isLocalTimezoneSelected = !this.isLocalTimezoneSelected;
    this.dataSharingService.isTimezoneLocal = this.isLocalTimezoneSelected;

    if (this.isLocalTimezoneSelected) {
      this.toastrService.info('Timezone switched to Local timezone', 'Local Timezone', {
        positionClass: 'toast-bottom-full-width'
      });
    } else {
      this.toastrService.info('Timezone switched to GMT timezone', 'GMT Timezone', {
        positionClass: 'toast-bottom-full-width'
      });
    }
  }

  onToggleTimeFormat() {
    this.isTime24HrsSelected = !this.isTime24HrsSelected;
    this.dataSharingService.isTime24Hrs = this.isTime24HrsSelected;

    if (this.isTime24HrsSelected) {
      this.toastrService.warning('Time format switched to 24 Hrs', 'Time format: 24Hrs', {
        positionClass: 'toast-bottom-full-width'
      });
    } else {
      this.toastrService.warning('Time format switched to 12 Hrs', 'Time format: 12Hrs', {
        positionClass: 'toast-bottom-full-width'
      });
    }
  }
}
