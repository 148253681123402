export const environment = {
  production: false,
  version:`0.5.9`,
  envName: `Dev`,
  AWS_REGION: `us-east-1`,
  redirectUri: `https://papidashboard.aptechdevlab.com/`,
  loginUrl: `https://foxap.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=5vskhhgr4c37em3bgem2f0o6i7&scope=email+openid&redirect_uri=https%3A%2F%2Fpapidashboard.aptechdevlab.com%2F`,
  logoutUrl: `https://foxap.auth.us-east-1.amazoncognito.com/logout?client_id=5vskhhgr4c37em3bgem2f0o6i7&logout_uri=https%3A%2F%2Fpapidashboard.aptechdevlab.com%2F`,
  getTokenUrl: `https://foxap.auth.us-east-1.amazoncognito.com/oauth2/token`,
  getTokenClientId: `5vskhhgr4c37em3bgem2f0o6i7`,
  getTokenRedirectUri: `https://papidashboard.aptechdevlab.com/`,
  searchUri: `https://vpc-logging-dev-apcapdev-use1-jsrtj3j24g4vocs74im5lg4roy.us-east-1.es.amazonaws.com`, //DEV
  // searchUri: `https://vpc-logging-qa-apqa-use1-qol53kbwiaaxt3vqeo3jts2sj4.us-east-1.es.amazonaws.com`, //QA
  searchIndex: `/papihistory*`,
  searchItemIndex: `/papisummary*`,
  // searchUriDetails: `/_search?default_operator=AND&size=20&sort=datetime:desc&q=`, //DEV
  metricsUri: `https://3mgy0c8hte.execute-api.us-east-1.amazonaws.com/v1`, //DEV
  //metricsUri: `https://2jpaxh1o58.execute-api.us-east-1.amazonaws.com/v1`, //QA
  metricsAPIKey: `3mgy0c8hte`,
  papiAPIID: `ksqwe5430h`,
  papiAPIkey: `UxF1agcqkeaIcULaVvueJ1yoVBojVGv22GS5khJs`,
  pusher: {
    key: `1233d4575f51ba1a056e`,
    cluster: `mt1`,
  }
  //metricsUri: `https://87s760taz8.execute-api.us-east-1.amazonaws.com/Alpha` //RYAN
  // tslint:disable-line
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import `zone.js/dist/zone-error`;  // Included with Angular CLI.
