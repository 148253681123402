import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BlockUIService } from '../services/block-ui.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private blockUIService: BlockUIService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.blockUIService.startBlocking(); // Start blocking UI when request starts
    return next.handle(request).pipe(
      finalize(() => {
        this.blockUIService.stopBlocking(); // Stop blocking UI when request completes
      })
    );
  }
}
