export class PusherEvent {
    public channel: string
    public region: PusherField
    public "Content-Type": PusherField
    public bucket_name: PusherField
    public key: PusherField
    public size: PusherField
    public etag: PusherField
    public versionId: PusherField
    public sequencer: PusherField
    public replicated: PusherField
    public origin: PusherField
    public "ap-state": PusherField
    public "lambda-version": PusherField
    public "ap-traceid": PusherField
    public "x-amz-meta-original-key": PusherField
    public "ap-role": PusherField
    public "ap-service": PusherField
}

export class PusherField {
    public DataType: string
    public StringValue: string
}