import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PapiEvent } from 'src/app/shared/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUtilsService } from 'src/app/shared/services';

@Component({
  selector: 'app-item-details-modal',
  templateUrl: './item-details-modal.component.html',
  styleUrls: ['./item-details-modal.component.css']
})
export class ItemDetailsModalComponent implements OnInit {
  @Input() data;
  public errorMessage: string = null;
  public stringifiedData: string;

  public defaultPageSize = 20;
  public skipRecords = 0;
  public currentPage = 1;
  public loading = false;
  public defaultOperator = 'AND';
  public sortByColumn = 'datetime';
  public sortDirection = 'desc';
  constructor(
    private http: HttpClient,
    public commonUtilsService: CommonUtilsService,
    private activeModal: NgbActiveModal,) { }

  ngOnInit() {
    console.log(this.data);
    this.getItemDetails();
  }

  getItemDetails() {
    if (this.data === null || this.data.itemid === '' || this.data.rsn === '') {
      this.errorMessage = 'Unable to load item details...';
      console.log('Must have both itemid and rsn...');
      return;
    }
    var url = environment.searchUri;
    if (environment.searchItemIndex != null && environment.searchItemIndex != '') {
      url = url + environment.searchItemIndex;
    }

    url += `/_search?default_operator=${this.defaultOperator}&size=${this.defaultPageSize}&sort=${this.sortByColumn}:${this.sortDirection}&q=itemid:${this.data.itemid}+rsn:${this.data.rsn}`
    // url = url + environment.searchUriDetails + 'itemid:' + this.data.itemid + '+rsn:' + this.data.rsn;
    // console.log(url);
    this.http.get(url).subscribe(response => {
      try {
        const item: PapiEvent = JSON.parse(JSON.stringify(response));
        this.data.media_type = item.hits.hits[0]._source.media_type;
        this.data.composition_type = item.hits.hits[0]._source.composition_type;
        this.data.slug = item.hits.hits[0]._source.slug;
        this.data.record_id = item.hits.hits[0]._source.record_id;

        this.stringifiedData = JSON.stringify(this.data);
      }
      catch (e) {
        this.errorMessage = 'Unable to load item details...';
        console.log(e);
      }
    });

    if (this.data.extra_data == null) {
      return;
    }

    this.data.extra_data.forEach(element => {
      if (element.name == 'event') {
        if (this.data.state == 'error' || (this.data.state == 'complete' && this.data.service == 'distribution')) {
          // retrieve saved search ids
          this.http.get(element.url).subscribe(response => {
            try {
              // console.log("Fetching result string from " + element.url);
              this.ParseAtom(response["Sns"]["Message"]);
            }
            catch (e) {
              console.log("error fetching saved search IDs:");
              console.log(e);
            }
          });
        }
      }
    });

  }

  private ParseAtom(atomText: string) {
    var doc = new DOMParser().parseFromString(atomText, 'text/xml');
    var result = doc.evaluate('atom:feed/atom:entry/d:directive/d:properties/d:property[@name="document.savedsearchids"]/@value', doc, this.nsResolver, XPathResult.STRING_TYPE, null);
    if (result.stringValue && typeof result.stringValue === 'string') {
      this.data.saved_search_ids = result.stringValue?.split(',');
    }
  }

  private nsResolver(prefix) {
    var ns = {
      'atom': 'http://www.w3.org/2005/Atom',
      'd': 'http://www.ap.org/esb/extensions/2013/directives/',
      'appl': 'http://ap.org/schemas/03/2005/appl',
      'appl_content': 'application/vnd.ap.appl+xml'
    };
    return ns[prefix] || null;
  }

  onDismiss() {
    this.activeModal.dismiss();
  }

  onClose() {
    this.activeModal.close();
  }
}
