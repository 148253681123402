import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PapiEvent, PapiEventItem, MultiSearchResponse, ItemLinks, ItemIdAggregationBucket } from 'src/app/shared/models';
import { environment } from '../../environments/environment';
import { interval, skip, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ItemDetailsModalComponent } from '../modals/item-details-modal/item-details-modal.component';
import { EventViewerModalComponent } from '../modals/event-viewer-modal/event-viewer-modal.component';
import { DataSharingService } from '../shared/services/data-sharing.service';
import { CommonUtilsService } from '../shared/services/common-utils.service';
import { AppComponent } from '../app.component';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIService } from '../shared/services/block-ui.service';

@Component({
  selector: 'app-errors-content',
  templateUrl: './errors-content.component.html',
  styleUrls: ['./errors-content.component.css']
})
export class ErrorsContentComponent implements OnInit {

  // public index = 0;
  public itemIdQuery = '';
  public fromDateQuery = '';
  public toDateQuery = '';
  public originRegionQuery = '';
  public subscription: Subscription;
  public storedItem: PapiEvent;
  public searchCount: number;
  public date: FormControl;
  public showWarnings = false;
  isLocalTimezoneSelected = false;
  isTime24HrsSelected = false;
  public maxMessageCharsToShow = 200;
  public defaultPageSize = 20;
  public skipRecords = 0;
  public currentPage = 1;
  public loading = false;
  public defaultOperator = 'AND';
  public sortByColumn = 'datetime';
  public sortDirection = 'desc';
  buttonsLayoutOptions = ['dropdown', 'icon-only', 'icon-with-text'];
  selectedActionButtonsLayout = this.buttonsLayoutOptions[2];

  constructor(private http: HttpClient,
    public dataSharingService: DataSharingService,
    public commonUtilsService: CommonUtilsService,
    private toastrService: ToastrService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.dataSharingService.isLocalTimezoneSelected$.subscribe(isLocalTimezoneSelected => {
      this.isLocalTimezoneSelected = isLocalTimezoneSelected;
    });
    this.dataSharingService.isTime24HrsSelected$.subscribe(isTime24HrsSelected => {
      this.isTime24HrsSelected = isTime24HrsSelected;
    });

    this.selectedActionButtonsLayout = this.dataSharingService.buttonsLayoutOption;
    this.date = new FormControl(new Date());

    const fromDate = new Date();
    const toDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);

    this.fromDateQuery = fromDate.toISOString().split('T')[0];
    this.toDateQuery = toDate.toISOString().split('T')[0];

    this.submit('', this.fromDateQuery, this.toDateQuery, '');
    const source = interval(60000);
    this.subscription = source.subscribe(val => this.getEventItems());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  changeActionButtonsLayout(layoutName: string = 'dropdown') {
    if (this.isValidButtonsLayoutOption(layoutName)) {
      this.selectedActionButtonsLayout = layoutName;
      this.dataSharingService.buttonsLayoutOption = this.selectedActionButtonsLayout;
    } else {
      console.log(`${layoutName} is not a valid Layout.`);
    }
  }

  private isValidButtonsLayoutOption(value) {
    return this.buttonsLayoutOptions.includes(value);
  }

  sortBy(column: string, direction: string) {
    this.sortByColumn = column;
    this.sortDirection = direction == 'asc' ? 'desc' : 'asc';

    this.getEventItems(0);
  }

  submit(search: string, fromDate: string, toDate: string, originRegion: string) {
    this.itemIdQuery = search;
    if (!fromDate) {
      const dt = new Date();
      fromDate = dt.setDate(dt.getDate() - 15).toString();
    }
    if (!toDate) {
      toDate = new Date().toString();
    }

    this.fromDateQuery = new Date(fromDate).toISOString().split('T')[0];
    this.toDateQuery = new Date(toDate).toISOString().split('T')[0];
    this.originRegionQuery = originRegion;

    this.getEventItems(0);
  }

  pageChanged(pageNum: number): void {

    this.skipRecords = pageNum * this.defaultPageSize;
    this.currentPage = Math.floor(this.skipRecords / this.defaultPageSize);
    this.getEventItems(this.skipRecords);
  }

  getNextItems() {
    const newIndex = this.skipRecords + this.defaultPageSize;
    this.pageChanged(newIndex);
  }

  getPrevItems() {
    const newIndex = Math.max(0, this.skipRecords - this.defaultPageSize);
    this.pageChanged(newIndex);
  }

  getEventItems(newIndex: number = 0) {
    let url = '';
    let requestBody = '';

    url = `${environment.searchUri}${environment.searchIndex}/_search`

    requestBody = `{
      "query": {
        "bool": {
          "must": [
            {
              "multi_match": {
                "query": "error ${this.itemIdQuery}",
                "type": "cross_fields",
                "fields": ["itemid", "traceid", "state"],
                "operator": "${this.defaultOperator}"
              }
            }`;

    if (!this.showWarnings) {
      requestBody += `,
            {
              "bool": {
                "must_not": [
                  {"term": {"level": "warning"}}
                ]
              }
            }`;
    }

    if (this.fromDateQuery || this.toDateQuery) {
      requestBody += `,
            {
              "range": {
                "datetime": {
                  ${this.fromDateQuery ? `"gte": "${this.fromDateQuery}"` : ''}
                  ${this.fromDateQuery && this.toDateQuery ? ',' : ''}
                  ${this.toDateQuery ? `"lte": "${this.toDateQuery}"` : ''}
                }
              }
            }`;
    }

    if (this.originRegionQuery) {
      let region = this.originRegionQuery;
      if (this.originRegionQuery.toLowerCase() == 'east') {
        region = 'us-east-1';
      } else if (this.originRegionQuery.toLowerCase() == 'west') {
        region = 'us-west-2'
      }

      requestBody += `,
            {
              "term": {
                "origin-region.keyword": "${region}"
              }
            }`;
    }

    requestBody += `
          ]
        }
      },
      "from": ${newIndex},
      "size": ${this.defaultPageSize},
      "aggs": {
        "itemids": {
          "terms": {
            "field": "itemid.keyword",
            "size": ${this.defaultPageSize * 1000}
          },
          "aggs": {
            "top_itemids": {
              "top_hits": {
                "sort": [{"${this.sortByColumn}": {"order": "${this.sortDirection}"}}],
                "size": ${this.defaultPageSize}
              }
            }
          }
        }
      },
      "sort": [
          {
              "${this.sortByColumn}": {
                  "order": "${this.sortDirection}"
              }
          }
      ]
    }`;

    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    this.http.post<PapiEvent>(url, requestBody, options).subscribe(data => {
      this.skipRecords = newIndex;
      this.currentPage = Math.floor(this.skipRecords / this.defaultPageSize);
      this.storedItem = data;
      this.searchCount = data.aggregations?.itemids?.buckets.length || 0;
    }, error => {
      this.toastrService.error(error);
    });
  }

  getItemDetails() {
    const url = environment.searchUri + environment.searchItemIndex + '/_msearch';
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    var requestBody = '';
    var itemid: string;
    var rsn: string;
    this.storedItem.aggregations.itemids.buckets.forEach(bucket => {
      requestBody = requestBody + '{}\n{ "query": { "bool": { "must": [ { "term": { "itemid": "' + bucket.top_itemids.hits.hits[0]._source.itemid + '" } }, { "term": { "rsn": "' + bucket.top_itemids.hits.hits[0]._source.rsn + '" } } ] } } }\n';
    });
    // console.log(url);
    // console.log(requestBody);
    this.http.post<MultiSearchResponse>(url, requestBody, options).subscribe(data => {
      data.responses.forEach(event => {
        if (event != null && event.hits != null && event.hits.hits != null && event.hits.hits.length > 0 && event.hits.hits[0]._source != null) {
          itemid = event.hits.hits[0]._source.itemid;
          rsn = event.hits.hits[0]._source.rsn;
          this.storedItem.aggregations.itemids.buckets.some(function (bucket, index) {
            if (bucket.top_itemids.hits.hits[0]._source.itemid == itemid && bucket.top_itemids.hits.hits[0]._source.rsn == rsn) {
              bucket.top_itemids.hits.hits[0]._source.slug = event.hits.hits[0]._source.slug;
              bucket.top_itemids.hits.hits[0]._source.composition_type = event.hits.hits[0]._source.composition_type;
              return true;
            }
          });
        }
      });
    });
  }

  openViewItemModal(item: PapiEventItem) {
    const popupData = item._source;

    const modalRef = this.modalService.open(ItemDetailsModalComponent, {
      scrollable: true,
      // size: 'xl',
    });
    modalRef.componentInstance.data = popupData;

    modalRef.result.then((result) => {
      console.log('The event dialog was closed', result);
    }, (reason) => {
      console.log('The event dialog was dismissed', reason);
    });
  }

  openViewEventModal(item: ItemLinks, service: string, state: string) {
    const popupData: ItemLinks[] = [
      item,
      this.createItem('service', service),
      this.createItem('status', state)
    ];

    const modalRef = this.modalService.open(EventViewerModalComponent, {
      scrollable: true,
      size: 'xl',
    });
    modalRef.componentInstance.data = popupData;

    modalRef.result.then((result) => {
      console.log('The event dialog was closed', result);
    }, (reason) => {
      console.log('The event dialog was dismissed', reason);
    });
  }

  createItem(name: string, value: string): ItemLinks {
    var newItem: ItemLinks = new ItemLinks();
    newItem.name = name;
    newItem.url = value;
    return newItem;
  }

  public onWarningsChanged(value: boolean) {
    this.showWarnings = value;
  }

}
