import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { interval, Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface MetricList {
  HourlyPublishCounts: string;
  HeartbeatProcessing: string;
  AudioCount: number;
  InteractiveCount: number;
  GraphicCount: number;
  PhotoCount: number;
  TextCount: number;
  VideoCount: number;
  APMSVisible: number;
  APMSAge: number;
  APMSVisibleBatch: number;
  APMSAgeBatch: number;
  ProductizationVisible: number;
  ProductizationAge: number;
  ProductizationVisibleBatch: number;
  ProductizationAgeBatch: number;
  GeocodingVisible: number;
  GeocodingAge: number;
  GeocodingVisibleBatch: number;
  GeocodingAgeBatch: number;
  ProfilerVisible: number;
  ProfilerAge: number;
  ProfilerVisibleBatch: number;
  ProfilerAgeBatch: number;
  FinalizeVisible: number;
  FinalizeAge: number;
  FinalizeVisibleBatch: number;
  FinalizeAgeBatch: number;
}

export interface MetricResponse {
  body: MetricList;
}

@Component({
  selector: 'app-metrics-content',
  templateUrl: './metrics-content.component.html',
  styleUrls: ['./metrics-content.component.css']
})
export class MetricsContentComponent implements OnInit {

  public sanitizedImageSrc: SafeResourceUrl;
  public sanitizedHeartbeatImageSrc: SafeResourceUrl;
  public audioCount = '--';
  public interactiveCount = '--';
  public graphicCount = '--';
  public photoCount = '--';
  public textCount = '--';
  public videoCount = '--';
  public PAPIProcessingTime = '--';
  public APMSVisible = '--';
  public APMSAge = '--';
  public APMSVisibleBatch = '--';
  public APMSAgeBatch = '--';
  public ProductizationVisible = '--';
  public ProductizationAge = '--';
  public ProductizationVisibleBatch = '--';
  public ProductizationAgeBatch = '--';
  public GeocodingVisible = '--';
  public GeocodingAge = '--';
  public GeocodingVisibleBatch = '--';
  public GeocodingAgeBatch = '--';
  public ProfilerVisible = '--';
  public ProfilerAge = '--';
  public ProfilerVisibleBatch = '--';
  public ProfilerAgeBatch = '--';
  public FinalizeVisible = '--';
  public FinalizeAge = '--';
  public FinalizeVisibleBatch = '--';
  public FinalizeAgeBatch = '--';
  public lastUpdated: Date;
  subscription: Subscription;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.getMetricData();
    const source = interval(300000);
    this.subscription = source.subscribe(val => this.getMetricData());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getMetricData(): void {
    this.getImageInfo().subscribe(imageInfo => {
      console.log(imageInfo);
      this.sanitizedImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + imageInfo.body.HourlyPublishCounts);
      this.sanitizedHeartbeatImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + imageInfo.body.HeartbeatProcessing);
      this.audioCount = this.translateCounts(imageInfo.body.AudioCount);
      this.interactiveCount = this.translateCounts(imageInfo.body.InteractiveCount);
      this.graphicCount = this.translateCounts(imageInfo.body.GraphicCount);
      this.photoCount = this.translateCounts(imageInfo.body.PhotoCount);
      this.textCount = this.translateCounts(imageInfo.body.TextCount);
      this.videoCount = this.translateCounts(imageInfo.body.VideoCount);
      this.APMSVisible = this.translateCounts(imageInfo.body.APMSVisible);
      this.APMSAge = this.translateCounts(imageInfo.body.APMSAge);
      this.APMSVisibleBatch = this.translateCounts(imageInfo.body.APMSVisibleBatch);
      this.APMSAgeBatch = this.translateCounts(imageInfo.body.APMSAgeBatch);
      this.ProductizationVisible = this.translateCounts(imageInfo.body.ProductizationVisible);
      this.ProductizationAge = this.translateCounts(imageInfo.body.ProductizationAge);
      this.ProductizationVisibleBatch = this.translateCounts(imageInfo.body.ProductizationVisibleBatch);
      this.ProductizationAgeBatch = this.translateCounts(imageInfo.body.ProductizationAgeBatch);
      this.GeocodingVisible = this.translateCounts(imageInfo.body.GeocodingVisible);
      this.GeocodingAge = this.translateCounts(imageInfo.body.GeocodingAge);
      this.GeocodingVisibleBatch = this.translateCounts(imageInfo.body.GeocodingVisibleBatch);
      this.GeocodingAgeBatch = this.translateCounts(imageInfo.body.GeocodingAgeBatch);
      this.ProfilerVisible = this.translateCounts(imageInfo.body.ProfilerVisible);
      this.ProfilerAge = this.translateCounts(imageInfo.body.ProfilerAge);
      this.ProfilerVisibleBatch = this.translateCounts(imageInfo.body.ProfilerVisibleBatch);
      this.ProfilerAgeBatch = this.translateCounts(imageInfo.body.ProfilerAgeBatch);
      this.FinalizeVisible = this.translateCounts(imageInfo.body.FinalizeVisible);
      this.FinalizeAge = this.translateCounts(imageInfo.body.FinalizeAge);
      this.FinalizeVisibleBatch = this.translateCounts(imageInfo.body.FinalizeVisibleBatch);
      this.FinalizeAgeBatch = this.translateCounts(imageInfo.body.FinalizeAgeBatch);
      this.lastUpdated = new Date();
    }, error => console.error(error));
  }

  translateCounts(count: number): string {
    if (count) {
      if (count > 999) {
        return (count / 1000).toFixed(1) + 'k';
      } else {
        return count.toString();
      }
    } else {
      return '--';
    }
  }

  getImageInfo(): Observable<MetricResponse> {
    return this.http.get<MetricResponse>(environment.metricsUri);
  }

}
