import { Time } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private isLocalTimezoneSelectedSubject: BehaviorSubject<boolean>;
  public isLocalTimezoneSelected$: Observable<boolean>;

  private isTime24HrsSelectedSubject: BehaviorSubject<boolean>;
  public isTime24HrsSelected$: Observable<boolean>;

  browserTimezoneOffset: number = new Date().getTimezoneOffset() / 60;


  private buttonsLayoutOptionSelectedSubject: BehaviorSubject<string>;
  public buttonsLayoutOptionSelected$: Observable<string>;

  constructor() {
    this.isLocalTimezoneSelectedSubject = new BehaviorSubject<boolean>(false);
    this.isLocalTimezoneSelected$ = this.isLocalTimezoneSelectedSubject.asObservable();
    const storedValueTimezoneLocal = localStorage.getItem('isLocalTimezoneSelected');
    if (storedValueTimezoneLocal) {
      this.isLocalTimezoneSelectedSubject.next(storedValueTimezoneLocal === 'true');
    }

    this.isTime24HrsSelectedSubject = new BehaviorSubject<boolean>(false);
    this.isTime24HrsSelected$ = this.isTime24HrsSelectedSubject.asObservable();
    const storedValueTimeFormat = localStorage.getItem('isTime24HrsSelected');
    if (storedValueTimeFormat) {
      this.isTime24HrsSelectedSubject.next(storedValueTimeFormat === 'true');
    }

    this.buttonsLayoutOptionSelectedSubject = new BehaviorSubject<string>('dropdown');
    this.buttonsLayoutOptionSelected$ = this.buttonsLayoutOptionSelectedSubject.asObservable();
    const storedValueButtonsLayoutSelected = localStorage.getItem('buttonsLayoutOption');
    if (storedValueButtonsLayoutSelected) {
      this.buttonsLayoutOptionSelectedSubject.next(storedValueButtonsLayoutSelected);
    }
  }

  set isTimezoneLocal(isLocalTimezoneSelected: boolean) {
    this.isLocalTimezoneSelectedSubject.next(isLocalTimezoneSelected);
    localStorage.setItem('isLocalTimezoneSelected', isLocalTimezoneSelected.toString());
  }

  get isTimezoneLocal(): boolean {
    return this.isLocalTimezoneSelectedSubject?.value || false;
  }

  set isTime24Hrs(isTime24Hrs: boolean) {
    this.isTime24HrsSelectedSubject.next(isTime24Hrs);
    localStorage.setItem('isTime24HrsSelected', isTime24Hrs.toString());
  }

  get isTime24Hrs(): boolean {
    return this.isTime24HrsSelectedSubject?.value || false;
  }

  get versionNumber(): string {
    return environment.version;
  }
  get environmentName(): string {
    return environment.envName;
  }

  adjustTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    let adjustedDate: Date;

    if (this.isLocalTimezoneSelectedSubject?.value) {
      adjustedDate = new Date(date.getTime());
    } else {
      adjustedDate = new Date(date.getTime() + (this.browserTimezoneOffset * 60 * 60 * 1000));
    }

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const month = monthNames[adjustedDate.getMonth()];
    const day = adjustedDate.getDate();
    const year = adjustedDate.getFullYear();
    const hour = adjustedDate.getHours();
    const minute = adjustedDate.getMinutes();
    const second = adjustedDate.getSeconds();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;

    let formattedTime: string;
    if (this.isTime24HrsSelectedSubject?.value) {
      formattedTime = `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`;
    } else {
      formattedTime = `${hour12 < 10 ? '0' + hour12 : hour12}:${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second} ${ampm}`;
    }
    return `${month} ${day}, ${year}, ${formattedTime}`;
  }


  adjustedTimeOnly(timestamp: string): string {
    const date = new Date(timestamp);
    let adjustedDate: Date;

    if (this.isLocalTimezoneSelectedSubject?.value) {
      adjustedDate = new Date(date.getTime() + (this.browserTimezoneOffset * 60 * 60 * 1000));
    } else {
      adjustedDate = new Date(date.getTime());
    }

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const month = monthNames[adjustedDate.getMonth()];
    const day = adjustedDate.getDate();
    const year = adjustedDate.getFullYear();
    const hour = adjustedDate.getHours();
    const minute = adjustedDate.getMinutes();
    const second = adjustedDate.getSeconds();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;

    let formattedTime: string;
    if (this.isTime24HrsSelectedSubject?.value) {
      formattedTime = `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`;
    } else {
      formattedTime = `${hour12 < 10 ? '0' + hour12 : hour12}:${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second} ${ampm}`;
    }
    return `${formattedTime}`;
  }

  set buttonsLayoutOption(option: string) {
    this.buttonsLayoutOptionSelectedSubject.next(option);
    localStorage.setItem('buttonsLayoutOption', option);
  }
  
  get buttonsLayoutOption(): string {
    return this.buttonsLayoutOptionSelectedSubject?.value || 'dropdown';
  }
}

