import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() currentPage: number;
  @Input() pageSize: number;
  @Input() totalItems: number;

  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  totalPages: number;

  constructor() { }

  ngOnChanges(): void {
    this.calculateTotalPages();
  }

  calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.totalItems / this.pageSize) - 1;
  }

  onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  getPagesBefore(): number[] {
    const startPage = Math.max(1, this.currentPage - 2);
    return Array.from({ length: Math.min(2, this.currentPage - 1) }, (_, i) => startPage + i);
  }

  getPagesAfter(): number[] {
    const endPage = Math.min(this.totalPages, this.currentPage + 2);
    return Array.from({ length: Math.min(2, this.totalPages - this.currentPage) }, (_, i) => endPage - 1 + i);
  }

}
