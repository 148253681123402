import { Component, OnInit, inject } from '@angular/core';
import { APLoginService, TokenPayload } from './shared/services';
import { JwtTokenHelper } from './shared/services/jwt-token-helper';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BlockUIService } from 'ng-block-ui';
import { AppUiService } from './shared/services/app-ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public authorized = false;
  public version: string = null;
  public env: string = null;
  isSidebarCollapsed: boolean = false;
  title = 'dashboard';

  constructor(private service: APLoginService,
    public blockUIService: BlockUIService,
    private appUiService: AppUiService,
    private toastrService: ToastrService) {
    this.version = environment.version;
    this.env = environment.envName;
  }

  ngOnInit() {
    this.appUiService.sidebarCollapsed$.subscribe(collapsed => {
      this.isSidebarCollapsed = collapsed;
    });
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
    if (currentUser) {
      console.log('User is authorized');
      this.authorized = true;
    } else {
      console.log('User is not authorized');
      const helper = new JwtTokenHelper();
      const code = helper.getParameterByName('code', null);
      if (code && code !== '') {
        console.log('Code is ' + code);
        this.service.getTokensFromCode(code).subscribe((data: TokenPayload) => this.assignToken(data))
      }
    }
  }

  private assignToken(data: TokenPayload) {
    if (this.service.assignToken(data)) {
      if (APLoginService.userTokens) {
        const helper = new JwtTokenHelper();
        const decoded = helper.decodeToken(APLoginService.userTokens.access_token);
        console.log(decoded);
        const myObj = JSON.parse(decoded);
        const username = myObj['username'];
        localStorage.setItem('currentUser', JSON.stringify({
          username: username,
          token: APLoginService.userTokens.access_token
        }));
        window.location.href = environment.redirectUri;
      }
    }
  }
}