import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent, HeaderComponent, SidebarComponent } from './layout';
import { ClockComponent } from './components';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgbModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { PaginationComponent } from './components/pagination/pagination.component';

import { BlockUIModule } from 'ng-block-ui';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';

@NgModule({
  declarations: [
    ClockComponent,
    PaginationComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    RouterLinkActive,
    NgbPopoverModule,
    NgbTooltipModule,
    ClipboardModule,
    BlockUIModule.forRoot(),

    ToastrModule.forRoot()
  ], 
  exports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ClockComponent,
    PaginationComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    NgbPopoverModule,
    NgbTooltipModule,
    ClipboardModule,

    BlockUIModule,
    ToastrModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true }
  ],
})
export class SharedModule { }
