// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pre {
    overflow: auto;
    word-wrap: normal;
    white-space: pre;
    max-height: 550px;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/modals/event-viewer-modal/event-viewer-modal.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;EACnB","sourcesContent":["pre {\n    overflow: auto;\n    word-wrap: normal;\n    white-space: pre;\n    max-height: 550px;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
