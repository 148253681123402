import { Injectable } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Injectable({
  providedIn: 'root'
})
export class BlockUIService {
  @BlockUI() blockUI: NgBlockUI;

  constructor() { }

  startBlocking() {
    this.blockUI.start(); // Start blocking UI
  }

  stopBlocking() {
    this.blockUI.stop(); // Stop blocking UI
  }
}
