// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-container {
  position: relative;
  width: 100%;
  height: 75vh;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/documentation/documentation.component.scss"],"names":[],"mappings":"AAaA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAZJ;;AAeA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AAZJ","sourcesContent":["// .documentation-content {\n//     width: 100%;\n//     height: 100%;\n//     /* Adjust the height as needed */\n//     overflow: auto;\n//     /* Add scrollbars if content overflows */\n// }\n\n// .card-body {\n//     height: 78vh;\n//     overflow-y: auto;\n// }\n\n.iframe-container {\n    position: relative;\n    width: 100%;\n    height: 75vh;\n    overflow: hidden;\n}\n\n.iframe-container iframe {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    border: 0;\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
