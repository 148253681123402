import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { CommonUtilsService } from '../../shared/services/common-utils.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HighlightService } from 'src/app/shared/services';
import { ItemLinks } from 'src/app/shared/models';

@Component({
  selector: 'app-event-viewer-modal',
  templateUrl: './event-viewer-modal.component.html',
  styleUrls: ['./event-viewer-modal.component.css']
})
export class EventViewerModalComponent implements OnInit {
  public eventText: any = null;
  public errorMessage: string = null;
  public useJsonPipe: boolean = true;
  public jsondata: any;
  public stringifiedData: string;
  public editorOptions: JsonEditorOptions;
  highlighted: boolean = false;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
  @Input() data: any;

  constructor(
    private http: HttpClient,
    public commonUtilsService: CommonUtilsService,
    private highlightService: HighlightService,
    private activeModal: NgbActiveModal,
  ) {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.mode = "code";
    this.editorOptions.modes = ["code"];
    this.editorOptions.mainMenuBar = false;
    // allowed modes --> 'code', 'text', 'tree', 'view'
  }

  ngOnInit() {
    this.getItem();
  }

  ngAfterViewChecked() {
    if (this.eventText && !this.highlighted) {
      this.highlightService.highlightAll();
      this.highlighted = true;
    }
  }

  getItem() {
    if (this.data === null || this.data.length < 1) {
      this.errorMessage = 'Unable to load event...';
      console.log('Event cannot be null or empty...');
      return;
    }
    var item: ItemLinks = this.data.pop();
    var status: string;
    var service: string;
    var url: string;
    var name: string;
    do {
      console.log('url', url);
      switch (item.name) {
        case 'service':
          service = item.url.toLowerCase();
          break;
        case 'status':
          status = item.url.toLowerCase();
          break;
        default:
          url = item.url;
          name = item.name;
          break;
      }
      item = this.data.pop();
    } while (item)
    
    if (name == 'event' || status == 'error' || (status == 'in-progress' && service == 'profiler')) {
      this.http.get(url).subscribe(response => {
        try {
          this.jsondata = response;
          this.stringifiedData = JSON.stringify(this.jsondata);
        }
        catch (e) {
          this.errorMessage = 'Unable to display event...';
          console.log(e);
        }
      });
    } else {
      this.http.get(url, { responseType: "text" }).subscribe(response => {
        try {
          this.jsondata = JSON.parse(response);
          this.stringifiedData = JSON.stringify(this.jsondata);
          return;
        }
        catch { }
        try {
          this.eventText = response;
        }
        catch (e) {
          this.errorMessage = 'Unable to display event...';
          console.log(e);
        }
      });
    }
  }

  onDismiss() {
    this.activeModal.dismiss();
  }

  onClose() {
    this.activeModal.close();
  }
}
