// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .box {
     border: 1px solid #ccc;
     border-radius: 5px;
     overflow: hidden;
 }

 .box-content {
     padding: 16px;
 }

 .box-icon {
     float: left;
 }

 .box-data {
     float: right;
 }

 .box-clear {
     clear: both;
 }`, "",{"version":3,"sources":["webpack://./src/app/metrics-content/metrics-content.component.css"],"names":[],"mappings":"CAAC;KACI,sBAAsB;KACtB,kBAAkB;KAClB,gBAAgB;CACpB;;CAEA;KACI,aAAa;CACjB;;CAEA;KACI,WAAW;CACf;;CAEA;KACI,YAAY;CAChB;;CAEA;KACI,WAAW;CACf","sourcesContent":[" .box {\n     border: 1px solid #ccc;\n     border-radius: 5px;\n     overflow: hidden;\n }\n\n .box-content {\n     padding: 16px;\n }\n\n .box-icon {\n     float: left;\n }\n\n .box-data {\n     float: right;\n }\n\n .box-clear {\n     clear: both;\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
