declare const Pusher: any;

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;
  stateChannel: any;
  alarmChannel: any;

  constructor(private http: HttpClient) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true
    });
    this.stateChannel = this.pusher.subscribe('state');
    this.alarmChannel = this.pusher.subscribe('alarm');
  }
}
