// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.primary tr:nth-child(even) {
    background-color: #f1f1f1;
}

table.primary tr:nth-child(odd) {
    background-color: #fff;
}

table.primary tr.batch {
    background-color: #92e2f0;
}
`, "",{"version":3,"sources":["webpack://./src/app/search-content/search-content.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["table.primary tr:nth-child(even) {\n    background-color: #f1f1f1;\n}\n\ntable.primary tr:nth-child(odd) {\n    background-color: #fff;\n}\n\ntable.primary tr.batch {\n    background-color: #92e2f0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
