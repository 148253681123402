import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileDownloadService {

    constructor(private http: HttpClient) { }

    downloadClassificationItemInOutData(item: any): void {
        if (item === null || item.length < 1) {
            console.log('Event cannot be null or empty...');
            return;
        }

        if (item._source.service === 'classification' && item._source.state === 'in-progress') {

            const cogX_url = item._source.extra_data.find(x => x.name === 'result').url;

            const parts = cogX_url.split('/');
            const desiredFilename = parts[parts.length - 1].split('?')[0];

            this.http.get(cogX_url, { responseType: "text" }).subscribe(response => {
                try {
                    this.downloadXmlFile(response, `${desiredFilename}.cogx`);
                }
                catch { }
            });
        }
    }

    private downloadXmlFile(data: string, filename: string): void {
        const blob = new Blob([data], { type: 'text/xml;charset=utf-8' });
        saveAs(blob, filename);
    }

}