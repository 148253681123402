import { Component, OnInit } from '@angular/core';
import { APLoginService } from 'src/app/shared/services/aplogin.service';
import { environment } from 'src/environments/environment';
import { AppUiService } from '../../services/app-ui.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  public env: string;
  
  constructor(private appUiService: AppUiService) {
    this.env = environment.envName;
  }

  ngOnInit() {
    // this.apLoginService.
  }

  toggleSidebar() {
    this.appUiService.toggleSidebar();
  }
}
