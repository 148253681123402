import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SearchContentComponent } from './search-content/search-content.component';
import { LoginComponent } from './login/login.component';
import { MetricsContentComponent } from './metrics-content/metrics-content.component';
import { ItemDetailsModalComponent } from './modals/item-details-modal/item-details-modal.component';
import { ErrorsContentComponent } from './errors-content/errors-content.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EventViewerModalComponent } from './modals/event-viewer-modal/event-viewer-modal.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { HighlightService } from './shared/services/highlight.service';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { LoggingInterceptor } from './shared/interceptors/logging.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    
    LoginComponent,
    
    MetricsContentComponent,
    SearchContentComponent,
    ErrorsContentComponent,
    ItemDetailsModalComponent,
    EventViewerModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,

    NgJsonEditorModule,
  ],
  providers: [
    HighlightService,
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
