export class PapiEvent {
    public took: string
    public timed_out: string
    public _shards: ShardInfo
    public hits: PapiEventCollection
    public aggregations: AggregationResults
}

export class ShardInfo {
    public total: number
    public successful: number
    public skipped: number
    public failed: number
}

export class PapiEventCollection {
    public total: TotalInfo
    public max_score: number
    public hits: PapiEventItem[]
}

export class TotalInfo {
    public value: number
    public relation: string
}

export class PapiEventItem {
    public _index: string
    public _type: string
    public _id: string
    public _score: string
    public _source: PapiEventItemDetails
}

export class PapiEventItemDetails {
    public rsn: string
    public itemid: string
    public traceid: string
    public region: string
    public service: string
    public state: string
    public role: string
    public is_batch: boolean
    public datetime: string
    public message: string
    public media_type: string
    public composition_type: string
    public record_id: string
    public slug: string
    public extra_data: ItemLinks[]
    public filing_ids: string[]
    public saved_search_ids: string[]
    public level: string
    public comp_type: string
    public tags: string
    public is_agg: boolean
}

export class ItemLinks {
    public url: string
    public name: string
}

export class AggregationResults {
    public itemids: ItemIdAggregationBucketCollection
}

export class ItemIdAggregationBucketCollection {
    public buckets: ItemIdAggregationBucket[]
}

export class ItemIdAggregationBucket {
    public top_itemids: PapiEvent
}

export class AggregatedResultCollection {
    public hits: PapiEventCollection
}

export class MultiSearchResponse {
    public responses: PapiEvent[]
}