import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class TokenPayload {
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: string;
  expires_in: number;
}

@Injectable({
  providedIn: 'root'
})
export class APLoginService {

  public static userTokens: TokenPayload;

  constructor(private http: HttpClient) { }

  public getTokensFromCode(code: string): Observable<TokenPayload> {
    const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();
    body = body.set('grant_type', 'authorization_code');
    body = body.set('client_id', environment.getTokenClientId);
    body = body.set('redirect_uri', environment.getTokenRedirectUri);
    body = body.set('code', code);
    return this.http.post<TokenPayload>(environment.getTokenUrl, body, { headers: header });
  }

  public assignToken(data:TokenPayload): boolean {
    console.log('attempting to assign tokens');
    APLoginService.userTokens = data;
    console.log('Assigned tokens - ' + APLoginService.userTokens.access_token);
    return true;
    //APLoginService.userTokens = {
    //  access_token: data['access_token'],
    //  refresh_token: data['refresh_token'],
    // id_token: data['id_token'],
    //  token_type: data['token_type'],
    //  expires_in: data['expires_in']
    // };
  }

  private clearToken(): void {
    APLoginService.userTokens = null;
  }

  addAccessToken(header: HttpHeaders): HttpHeaders {
    if (!header) {
        header = new HttpHeaders();
    }
    if (APLoginService.userTokens) {
        header = header.set('Authorization', 'Bearer ' + APLoginService.userTokens.access_token);
    }
    return header;
  }

  addIdToken(header: HttpHeaders): HttpHeaders {
    if (!header) {
        header = new HttpHeaders();
    }
    if (APLoginService.userTokens) {
        header = header.set('Authorization', 'Bearer ' + APLoginService.userTokens.id_token);
    }
    return header;
  }
}