import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  constructor(private http: HttpClient) {}

  getDocumentation(fromUrl: string): Observable<string> {
    const documentationUrl = fromUrl; 
    return this.http.get(documentationUrl, { responseType: 'text' });
  }
}