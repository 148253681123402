import { NgModule } from '@angular/core';
import { MetricsContentComponent } from './metrics-content/metrics-content.component';
import { SearchContentComponent } from './search-content/search-content.component';
import { ErrorsContentComponent } from './errors-content/errors-content.component';
import { RouterModule, Routes } from '@angular/router';
import { DocumentationComponent } from './documentation/documentation.component';


const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' }, // Default route redirects to /home
  { path: 'dashboard', component: MetricsContentComponent },
  { path: 'search', component: SearchContentComponent },
  { path: 'errors', component: ErrorsContentComponent },
  { path: 'documentation/:slug', component: DocumentationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
