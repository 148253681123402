import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsService {

  constructor(private toastrService: ToastrService) { }

  copyText: string = '';

  onCopySuccess() {
    this.toastrService.success('Text Copied');
    console.log('Copied successfully');
  }

  onCopyError() {
    this.toastrService.error('Unable to copy, Try again');
    console.error('Failed to copy');
  }
}
