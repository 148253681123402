// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    display: flex;
    flex-direction: row;
  }
  
  .sidebar-collapsed .main {
    margin-left: 0;
    width: 100%;
  }
  
  .sidebar-collapsed app-sidebar {
    width: 0;
    overflow: hidden;
  }
  
  app-sidebar {
    width: 260px; /* Adjust the width as needed */
    transition: width 0.3s;
  }
  
  .main {
    flex-grow: 1;
    /* margin-left: 260px;  */
    /* Same as sidebar width */
    transition: margin-left 0.3s;
  }
  
  .toggle-sidebar-btn {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,WAAW;EACb;;EAEA;IACE,QAAQ;IACR,gBAAgB;EAClB;;EAEA;IACE,YAAY,EAAE,+BAA+B;IAC7C,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,0BAA0B;IAC1B,4BAA4B;EAC9B;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: row;\n  }\n  \n  .sidebar-collapsed .main {\n    margin-left: 0;\n    width: 100%;\n  }\n  \n  .sidebar-collapsed app-sidebar {\n    width: 0;\n    overflow: hidden;\n  }\n  \n  app-sidebar {\n    width: 260px; /* Adjust the width as needed */\n    transition: width 0.3s;\n  }\n  \n  .main {\n    flex-grow: 1;\n    /* margin-left: 260px;  */\n    /* Same as sidebar width */\n    transition: margin-left 0.3s;\n  }\n  \n  .toggle-sidebar-btn {\n    background: none;\n    border: none;\n    font-size: 1.5em;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
