import { Component, ElementRef, OnInit } from '@angular/core';
import { DocumentationService } from '../shared/services/documentation.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-documentation',
  standalone: true,
  imports: [],
  templateUrl: './documentation.component.html',
  styleUrl: './documentation.component.scss'
})
export class DocumentationComponent implements OnInit {
  public slug: string = 'papi.html';
  htmlContent: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private documentationService: DocumentationService,
    private elementRef: ElementRef) {

  }
  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get('slug');
    // var url = `/gitlab/papi/papi-core/-/raw/dev/docs/${this.slug}?ref_type=heads`;
    var url = `/assets/${this.slug}`;
    console.log(url);
    this.documentationService.getDocumentation(url).subscribe(
      (content: string) => {
        this.htmlContent = content;
      },
      (error: any) => {
        console.error('Error retrieving documentation:', error);
      }
    );
  }

  handleLinkClick(event: MouseEvent): void {
    console.log('clicked');
    const target = event.target as HTMLElement;

    // Check if the clicked element is an anchor tag
    if (target.tagName === 'A') {
      // Prevent default behavior (opening the link)
      event.preventDefault();

      // Get the href attribute of the anchor tag
      const href = target.getAttribute('href');

      // Scroll to the corresponding section of the page
      if (href && href.startsWith('#')) {
        const sectionId = href.substring(1); // Remove the '#' character
        const sectionElement = this.elementRef.nativeElement.querySelector(`#${sectionId}`);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }
}
