import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent {
  public version: string = null;
  public env: string = null;

  constructor() {
    this.version = environment.version;
    this.env = environment.envName;
  }

}
