import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { EventViewerModalComponent } from '../modals/event-viewer-modal/event-viewer-modal.component';
import { ItemDetailsModalComponent } from '../modals/item-details-modal/item-details-modal.component';
import { PapiEvent, PapiEventItem, ItemLinks } from '../shared/models';
import { DataSharingService, CommonUtilsService } from '../shared/services';
import { S3DownloadService } from '../shared/services/s3-download.service';
import { FileDownloadService } from '../shared/services/file-download.service';
import { Observable } from 'rxjs';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-search-content',
  templateUrl: './search-content.component.html',
  styleUrls: ['./search-content.component.css']
})
export class SearchContentComponent implements OnInit {
  public itemId = '';

  isLocalTimezoneSelected: boolean;

  storedItem: PapiEvent | undefined;
  storedSearchQuery: string = '*';
  storedRsnQuery: string = '';
  storedOriginRegionQuery: string = '';
  storedServiceQuery: string = '';
  searchCount: number = 0;

  public defaultPageSize = 20;
  public skipRecords = 0;
  public currentPage = 1;
  public loading = false;
  public defaultOperator = 'AND';
  public sortByColumn = 'datetime';
  public sortDirection = 'desc';

  buttonsLayoutOptions = ['dropdown', 'icon-only', 'icon-with-text'];
  selectedActionButtonsLayout = this.buttonsLayoutOptions[2];

  constructor(private http: HttpClient,
    public dataSharingService: DataSharingService,
    public commonUtilsService: CommonUtilsService,
    private fileDownloadService: FileDownloadService,
    private toastrService: ToastrService,
    private modalService: NgbModal) {
    this.isLocalTimezoneSelected = this.dataSharingService.isTimezoneLocal;
    this.selectedActionButtonsLayout = this.dataSharingService.buttonsLayoutOption;
  }

  ngOnInit() {
    this.dataSharingService.isLocalTimezoneSelected$.subscribe(isLocalTimezoneSelected => {
      this.isLocalTimezoneSelected = isLocalTimezoneSelected;
    });

    this.dataSharingService.buttonsLayoutOptionSelected$.subscribe(selectedActionButtonsLayout => {
      this.selectedActionButtonsLayout = selectedActionButtonsLayout;
    });

    this.submit('', '', '', '');
  }

  submit(search: string, rsn: string, service: string, originRegionQuery: string) {
    this.storedSearchQuery = search;
    this.storedRsnQuery = rsn;
    this.storedServiceQuery = service;
    this.storedOriginRegionQuery = originRegionQuery;

    if (originRegionQuery.toLowerCase() == 'east') {
      this.storedOriginRegionQuery = 'us-east-1';
    } else if (originRegionQuery.toLowerCase() == 'west') {
      this.storedOriginRegionQuery = 'us-west-2'
    }
    this.getEventItems(0);
  }

  sortBy(column: string, direction: string) {
    this.sortByColumn = column;
    this.sortDirection = direction == 'asc' ? 'desc' : 'asc';

    this.getEventItems(0);
  }

  pageChanged(pageNum: number): void {

    this.skipRecords = pageNum * this.defaultPageSize;
    this.currentPage = Math.floor(this.skipRecords / this.defaultPageSize);

    this.getEventItems(this.skipRecords);
    // this.getEventItems();
  }

  getNextItems() {
    const newIndex = this.skipRecords + this.defaultPageSize;
    this.pageChanged(newIndex);
  }

  getPrevItems() {
    const newIndex = Math.max(0, this.skipRecords - this.defaultPageSize);
    this.pageChanged(newIndex);
  }

  getEventItems(newIndex: number = 0) {
    // Set default stored search query if null or empty
    let url = '';
    let requestBody = '';

    if (!this.storedSearchQuery) {
      this.storedSearchQuery = '*';
    }

    url = `${environment.searchUri}${environment.searchIndex}/_search?default_operator=${this.defaultOperator}&from=${newIndex}&size=${this.defaultPageSize}&sort=${this.sortByColumn}:${this.sortDirection}&q=${this.storedSearchQuery}`;

    // Append storedRsnQuery and storedServiceQuery if they exist
    if (this.storedRsnQuery) {
      url += `+rsn:${this.storedRsnQuery}`;
    }
    if (this.storedServiceQuery) {
      url += `+service:${this.storedServiceQuery}`;
    }
    if (this.storedOriginRegionQuery) {
      url += `+origin-region:${this.storedOriginRegionQuery}`;
    }

    this.http.get(url).subscribe(
      (data: any) => {
        if (data) {
          this.processEventData(data, newIndex);
        }
      },
      error => console.error('Error fetching items:', error)
    );
  }

  private processEventData(data: any, newIndex: number) {
    this.storedItem = { ...data }; // Copying data to avoid mutation
    this.searchCount = this.storedItem?.hits?.total?.value || 0;
    this.skipRecords = newIndex;
    this.currentPage = Math.floor(this.skipRecords / this.defaultPageSize);

    // Add properties to each element in the hits array
    this.storedItem?.hits?.hits?.forEach(element => {
      try {
        element._source.is_batch = element._source.role?.includes('batch');
        element._source.is_agg = element._source.tags?.includes('aggregate');
      } catch (error) {
        console.error('Error setting properties:', error);
        element._source.is_batch = false;
      }
    });

    this.storedItem?.hits?.hits?.forEach(hit => {
      const cogX_url = hit._source.extra_data.find(x => x.name === 'result').url;
      let isInOrOut = 'In';
      if (cogX_url.indexOf('In.CogX') > -1) {
        isInOrOut = 'In';
      } else if (cogX_url.indexOf('Out.CogX') > -1) {
        isInOrOut = 'Out';
      } else {
        isInOrOut = '';
      }
      hit._source['CogX_Type'] = isInOrOut;
    });
  }

  openViewItemModal(item: PapiEventItem) {
    const popupData = item._source;

    const modalRef = this.modalService.open(ItemDetailsModalComponent, {
      scrollable: true,
      // size: 'xl',
    });
    modalRef.componentInstance.data = popupData;

    modalRef.result.then((result) => {
      console.log('The event dialog was closed', result);
    }, (reason) => {
      console.log('The event dialog was dismissed', reason);
    });
  }

  downloadCogX(item: PapiEventItem) {
    this.fileDownloadService.downloadClassificationItemInOutData(item);
  }

  changeActionButtonsLayout(layoutName: string = 'dropdown') {
    if (this.isValidButtonsLayoutOption(layoutName)) {
      this.selectedActionButtonsLayout = layoutName;
      this.dataSharingService.buttonsLayoutOption = this.selectedActionButtonsLayout;
    } else {
      console.log(`${layoutName} is not a valid Layout.`);
    }
  }

  private isValidButtonsLayoutOption(value) {
    return this.buttonsLayoutOptions.includes(value);
  }

  openModal(item: ItemLinks, service: string, state: string) {
    const popupData: ItemLinks[] = [
      item,
      this.createItem('service', service),
      this.createItem('status', state)
    ];

    const modalRef = this.modalService.open(EventViewerModalComponent, {
      scrollable: true,
      size: 'xl',
    });
    modalRef.componentInstance.data = popupData;

    modalRef.result.then((result) => {
      console.log('The event dialog was closed', result);
    }, (reason) => {
      console.log('The event dialog was dismissed', reason);
    });

  }

  createItem(name: string, value: string): ItemLinks {
    var newItem: ItemLinks = new ItemLinks();
    newItem.name = name;
    newItem.url = value;
    return newItem;
  }

  getRsn(url: string): string {
    let rsn = "";
    try {
      rsn = url.split(".", 2)[1].split("_", 2)[0];
    }
    catch { }
    return rsn;
  }

}
